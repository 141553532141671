import React, { useState, useEffect, useRef } from 'react';
import "../styles/Home.css"
import "../styles/ScrollPsiZoom.css"
import { useTypographyStyles, useLayoutStyles } from '../styles';
import { useNavigate } from "react-router-dom";
import { YellowBtnContained } from "../components/YellowBtn";

function throttle(func, limit) {
	let inThrottle;
	return function (...args) {
	  const context = this;
	  if (!inThrottle) {
		func.apply(context, args);
		inThrottle = true;
		setTimeout(() => (inThrottle = false), limit);
	  }
	};
  }

const ScrollPsiZoom = () => {
  const navigate = useNavigate();

	const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();
	
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const animationRef = useRef(null);
  const totalImages = 80;
  // const animationFrames = totalImages - 1; // Zero-based index
  const animationScrollHeight = window.innerHeight; // Adjust based on desired scroll length for the animation
  const desiredOffset = 0;
	// const animationDuration = window.innerHeight;


  // Detect if the device is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);


	// const [animationDuration, setAnimationDuration] = useState(0);
  const [animationDuration, setAnimationDuration] = useState(7000);


  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 1200);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

	// for throttling scroll effect
	//useEffect(() => {
	// 	const handleScroll = throttle(() => {
	// 	// Your scroll logic here
	// 	// For example:
	// 	const scrollTop = window.scrollY || document.documentElement.scrollTop;
	// 	// ... rest of your scroll handling code
	// 	}, 0.1); // The handler will execute at most once every 50 milliseconds

	// 	window.addEventListener('scroll', handleScroll);
	// 	return () => window.removeEventListener('scroll', handleScroll);
	// }, [/* your dependencies */]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 1200);
			if (window.innerWidth <= 600) {
				const componentHeight = animationRef.current.clientHeight;
				setAnimationDuration(2000);
			}
			else
			{
				setAnimationDuration(7000);
			}
		};	
		window.addEventListener('resize', handleResize);
		handleResize(); // Initialize on component mount
	
		return () => window.removeEventListener('resize', handleResize);
	}, []);


  // Preload images
  useEffect(() => {
    const imageUrls = [];
    for (let i = 1; i <= totalImages; i++) {
      const indexString = String(i).padStart(2, '0');
      imageUrls.push(`https://d3fcjysp85tqc8.cloudfront.net/home_page/psi_scroll_zoom/${indexString}.webp`);
    }

    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }, []);



	useEffect(() => {
		const handleScroll = () => {
			if (!animationRef.current) return;
	
			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			const componentOffsetTop = animationRef.current.offsetTop;
	
			const animationStartScroll = componentOffsetTop - desiredOffset;
			const animationEndScroll = animationStartScroll + animationDuration;
	
			if (scrollTop >= animationStartScroll && scrollTop <= animationEndScroll) {
				const scrollProgress = (scrollTop - animationStartScroll) / animationDuration;
				let index = Math.min(
					totalImages - 1,
					Math.floor(scrollProgress * totalImages)
				);
				if (Math.abs(index - currentImageIndex) > 1) {
					index += Math.ceil((currentImageIndex - index) / 2);
				}
				setCurrentImageIndex(index);
			} else if (scrollTop < animationStartScroll) {
				setCurrentImageIndex(0);
			} else if (scrollTop > animationEndScroll) {
				setCurrentImageIndex(totalImages - 1);
			}
		};
	
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [animationDuration, totalImages, currentImageIndex]);


	// const containerHeight = window.innerHeight + animationDuration - isMobile * window.innerHeight / 2.4;
	const containerHeight = window.innerHeight + animationDuration;

  // Styles
  const containerStyle = {
    position: 'relative',
    height: `${containerHeight}px`,
    width: '100%',
    display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
  };

  return (
		<>
			{ isMobile ? (
				<>
					<div className="animationTextStyle">
						<div className="animationContainer">
							<div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
								<div className={`${typogClasses.subSubHeading} ${typogClasses.textLeftCentre}`}>
									<br/>
									<b>What is a Spinal Fusion Implant?</b>
								</div>
								<div style={{textAlign: "justify", marginBottom: 0}} className={`${typogClasses.paragraphSmall} ${typogClasses.textJustifyCentre}`}>
								Is a spacer that is placed between two spinal bones (vertebrae) to permanently connect (fuse) that small portion of the spine.  The implant works by decompressing pinched nerves, realigning and stabilising the spinal segment.
								</div>
							</div>
						</div>
					</div>
					<div className="animationTextStyle">
						<div className="animationContainer">
							<div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
								<div className={`${typogClasses.subSubHeading} ${typogClasses.textLeftCentre}`}>
									<br/>
									<b>What is a Patient Specific Implant?</b>
								</div>
								<div style={{textAlign: "justify", marginBottom: 0}} className={`${typogClasses.paragraphSmall} ${typogClasses.textJustifyCentre}`}>
								Patient specific implants are custom built to fit the unique anatomy of each patient, unlike generic spinal fusion implants which only come in a standard shape.
								</div>
							</div>
						</div>
					</div>

					<div className="animationTextStyle">
						<div className="animationContainer">
							<div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
								<div className={`${typogClasses.subSubHeading} ${typogClasses.textLeftCentre}`}>
									<b>Why Patient Specific?</b>
								</div>
								<div style={{textAlign: "justify"}} className={`${typogClasses.paragraphSmall} ${typogClasses.textJustifyCentre}`}>
								Patient specific implants provide a snug fit, providing better support, comfort, and spinal alignment. The snug fit reduces the risk of injury and helps long-term stabilisation through  fusion, restoring the patient’s health and confidence. 
								</div>
							</div>
						</div>
					</div>

				<div ref={animationRef} style={containerStyle}>

					{/* Mobile Layout */}
					<div className="stickyContentStylePhone">
						<div className="animationImageContainer" style={{marginTop: 300}}>
							<img
								src={`https://d3fcjysp85tqc8.cloudfront.net/home_page/psi_scroll_zoom/${String(Math.floor(currentImageIndex) + 1).padStart(2, '0')}.webp`}
								alt={`Frame ${Math.floor(currentImageIndex) + 1}`}
								style={{ maxWidth: '100%', maxHeight: '100%' }}
							/>
						</div>

						<div className="animationTextStyle" style={{marginBottom: 300, marginTop: 20,}}>
							<div className="animationContainer">
								<div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
									<div className={`${typogClasses.subSubHeading} ${typogClasses.textLeftCentre}`}>
										<b>Why 3DMorphic?</b>
									</div>
									<div style={{textAlign: "justify", marginBottom: 10}} className={`${typogClasses.paragraphSmall} ${typogClasses.textJustifyCentre}`}>
									3DMorphic identified an opportunity to improve spinal fusion outcomes with better-fitting implants. Our in-house design and manufacturing process allows us to rapidly produce implants tailored to both surgeon and patient needs, enabling precise, patient-specific solutions. 
									{/* Explore our clinical trial results to see our commitment to achieving the best patient outcomes. */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				<br />


			</>

				) : (
				<div ref={animationRef} style={containerStyle}>
				<div className="stickyContentStyle">
					<div className="animationImageContainer">
						<img
								src={`https://d3fcjysp85tqc8.cloudfront.net/home_page/psi_scroll_zoom/${String(Math.floor(currentImageIndex) + 1).padStart(2, '0')}.webp`}
								alt={`Frame ${Math.floor(currentImageIndex) + 1}`}
								style={{ maxWidth: '100%', maxHeight: '100%' }}
							/>
					</div>
					<div className="animationTextStyle">
						<div className="animationContainer">
							<div className={`${layoutClasses.column} ${layoutClasses.flex1}`}style={{marginTop: 55}}>
								<div className={`${typogClasses.subSubHeading} ${typogClasses.textLeftCentre}`}>
								<b>What is a Spinal Fusion Implant?</b>
								</div>
								<div style={{textAlign: "justify"}} className={`${typogClasses.paragraphSmall} ${typogClasses.textJustifyCentre}`}>
								Is a spacer that is placed between two spinal bones (vertebrae) to permanently connect (fuse) that small portion of the spine.  The implant works by decompressing pinched nerves, realigning and stabilising the spinal segment.
								</div>
							</div>
							<div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
								<div style={{zIndex: 99, position: 'relative'}} className={`${typogClasses.subSubHeading} ${typogClasses.textLeftCentre}`}>
									<b style={{zIndex: 99, position: 'relative'}} >What is a Patient Specific Implant?</b>
								</div>
								<div style={{textAlign: "justify"}} className={`${typogClasses.paragraphSmall} ${typogClasses.textJustifyCentre}`}>
								Patient specific implants are custom built to fit the unique anatomy of each patient, unlike generic spinal fusion implants which only come in a standard shape.
								</div>
							</div>
							<div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
								<div style={{zIndex: 99, position: 'relative'}} className={`${typogClasses.subSubHeading} ${typogClasses.textLeftCentre}`}>
									<b style={{zIndex: 99, position: 'relative'}} >Why Patient Specific?</b>
								</div>
								<div style={{textAlign: "justify"}} className={`${typogClasses.paragraphSmall} ${typogClasses.textJustifyCentre}`}>
								Just like a fingerprint, no two people share the same spinal shape. Patient specific implants provide a snug fit, no matter the shape, unlike generic implants. 
								They offer better alignment, and stronger support, reducing the risk of recurring symptoms and promoting long-term health.
								</div>
							</div>
							<div className={`${layoutClasses.column} ${layoutClasses.flex1}`}>
								<div style={{zIndex: 99, position: 'relative'}} className={`${typogClasses.subSubHeading} ${typogClasses.textLeftCentre}`}>
									<b style={{zIndex: 99, position: 'relative'}} >Why 3DMorphic?</b>
								</div>
								<div style={{textAlign: "justify"}} className={`${typogClasses.paragraphSmall} ${typogClasses.textJustifyCentre}`}>
								3DMorphic identified an opportunity to improve spinal fusion outcomes with better-fitting implants. Our in-house design and manufacturing process allows us to rapidly produce implants tailored to both surgeon and patient needs, enabling precise, patient-specific solutions. 
								{/* Explore our clinical trial results to see our commitment to achieving the best patient outcomes. */} 
								</div>
							</div>
						</div>
						<YellowBtnContained style={{width: "200px", marginTop: "30px"}} onClick={() => navigate("/Difference")}>How it works</YellowBtnContained>
					</div>
				</div>
				</div>
			)}
		</>
  );
};

export default ScrollPsiZoom;
